import React from 'react'
import { Card, CardBody, CardText, Button, Row, Col } from 'reactstrap'
import cn from 'classnames'
import {
  truStageLogo,
  salesForceLogo,
  sftpLogo,
  scymitarLogo
} from './integrationLogos'
import plusIcon from '~/components/icons/plus.svg'
import bookIcon from '~/components/icons/book.svg'
import Link from '~/components/Link'

interface Integration {
  id: number
  logo?: {
    url: string
    width: number
  }
  title?: string
  description: string
  buttonLabel: string
  buttonAction?: () => void
  isConnected: boolean
}

const IntegrationSection: React.FC = () => {
  const integrations: Integration[] = [
    {
      id: 1,
      logo: { url: sftpLogo, width: 83 },
      description:
        'Effortlessly transfer data with secure SFTP connections, enabling streamlined access to member information and valuable insights.',
      buttonLabel: 'Connect SFTP',
      isConnected: false
    },
    {
      id: 2,
      logo: { url: salesForceLogo, width: 195 },
      description:
        'Leverage Salesforce Marketing Cloud to gain deeper customer insights, creating personalized and effective marketing campaigns.',
      buttonLabel: 'Connect Salesforce',
      isConnected: false
    },
    {
      id: 3,
      logo: { url: scymitarLogo, width: 224 },
      description:
        'Integrate with Symitar to access comprehensive member data, enhancing the precision of your targeted marketing strategies.',
      buttonLabel: 'Connect Symitar',
      isConnected: false
    },
    {
      id: 4,
      logo: { url: truStageLogo, width: 262 },
      description:
        'TruStage Digital Storefront delivers digital banking insights, driving better customer engagement and targeted marketing solutions.',
      buttonLabel: 'Connect TruStage',
      isConnected: false
    }
  ]

  return (
    <>
      <Row>
        {integrations.map((integration) => (
          <Col
            xs="6"
            xxxl="4"
            widths={['xs', 'xxxl']}
            key={integration.id}
            className="mb-4">
            <Card className="py-3 px-4 mb-0 h-100">
              <CardBody className="d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex align-items-center mb-3">
                    {integration.logo && (
                      <img
                        src={integration.logo.url}
                        alt={`${integration.title} logo`}
                        width={integration.logo.width}
                        className="me-2"
                      />
                    )}
                    {integration.title && (
                      <h2 className="text-18">{integration.title}</h2>
                    )}
                  </div>
                  <CardText className="text-18">
                    {integration.description}
                  </CardText>
                </div>
                <Link
                  target="_blank"
                  href="https://info.pulsatehq.com/automation-waitlist">
                  <Button
                    color=""
                    className={cn(
                      'btn-success mt-4 text-base rounded-lg shadow-md font-medium align-self-start px-4'
                    )}>
                    <img src={plusIcon} alt={integration.buttonLabel} />
                    <span className="ms-3">{integration.buttonLabel}</span>
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}

        <Col xs="6" xxxl="4" widths={['xs', 'xxxl']} className="mb-4">
          <Card className="pb-3 pt-5 px-4 mb-0 h-100 bg-catskill-white">
            <CardBody>
              <div className="d-flex align-items-center mb-3 mt-2">
                <p className="text-24 mb-0 fw-bold">Custom Integration</p>
              </div>
              <CardText className="text-18">
                Tailor your data connections with custom integrations, providing
                flexible access to data for refined targeting and personalised
                campaigns
              </CardText>
              <Link
                target="_blank"
                href="https://info.pulsatehq.com/automation-waitlist">
                <Button
                  color=""
                  className={cn(
                    'mt-3 border rounded-lg text-black shadow-md border-mine-shaft px-4 no-wrap text-base bg-white'
                  )}>
                  <img src={bookIcon} alt="Learn more" />
                  <span className="ms-3">Learn more</span>
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default IntegrationSection
