import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { ID, IGeneralBulkActions, IMetaData } from '~/common.interface'
import { exportSegmentUsers, fetchSegments } from '~/api/segments'
import {
  ISegmentDTO,
  ISegmentsListQueryParams,
  SEGMENT_STATUS
} from './SegmentsList.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { useStore } from '~/dataStore'

export enum ServerStateKeysEnum {
  Segments = 'segments'
}

export const useInvalidateSegments = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Segments])
}

export const useExportUsers = () => {
  const cache = useQueryClient()
  const {
    app: { currentAdmin, currentApp }
  } = useStore()
  return useMutation(
    (data: { id: ID }) => exportSegmentUsers(currentApp.id, data.id),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.Segments])
        showNotification(
          `Users exported to ${currentAdmin.email}`,
          NotificationType.SUCCESS
        )
      },
      onError: () => {
        showNotification(
          'Something went wrong exporting users',
          NotificationType.ERROR
        )
      }
    }
  )
}

const useSegmentsList = (
  appId: ID,
  queryParams: Omit<ISegmentsListQueryParams, 'page' | 'per_page'>
) => {
  return useInfiniteQuery(
    [
      ServerStateKeysEnum.Segments,
      {
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      fetchSegments(appId, {
        ...queryParams,
        page: pageParam
      }),
    {
      refetchInterval: 10000,
      select: (data: {
        pages: Array<{
          data: ISegmentDTO[]
          metadata: IMetaData
          bulkActions: IGeneralBulkActions & {
            resume: string
            pause: string
            hideFromFeed: string
          }
        }>
        pageParams: Array<number | undefined>
      }) => ({
        pages: data.pages
          .flatMap((page) => page.data)
          .map((segment) => ({
            ...segment,
            disabled: segment.status === SEGMENT_STATUS.IN_PROGRESS,
            groups: data.pages[0].metadata.groups.filter((g) =>
              segment.groupsIds.includes(g.id)
            ),
            batchActionUrls: data.pages[0].bulkActions
          })),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata,
        bulkActions: data.pages[0].bulkActions
      }),
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export default useSegmentsList
